vbase
<template>
  <div class="showcase">
    <div>
      <div class="showcase__top-lines" />
      <b-container class="py-5">
        <b-row>
          <b-col xl="4" class="project__content">
            <article>
              <h2 class="main-heading">{{ $t("title.our_store") }}</h2>
              <div class="main-description" v-html="storeDescription" />
              <Button
                class="button button--md button--outline-primary my-4 mx-0"
                to="/store"
                :text="$t('button.see_all')"
              ></Button>
            </article>
          </b-col>
          <b-col xl="8">
            <lazy-component>
              <showcase :items="items" />
            </lazy-component>
          </b-col>
        </b-row>
      </b-container>
      <div class="showcase__bottom-lines" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { IndexData } from "@/helpers/apiMethods";

export default {
  components: {
    Showcase: () => import("@/components/Showcase/Home"),
  },

  data() {
    return {
      items: [],
      storeDescription: "",
    };
  },
  created() {
    this.fetchProjects();
  },
  computed: {
    ...mapGetters(["GlobalSettings", "settings"]),
  },
  watch: {
    GlobalSettings: {
      handler() {
        this.storeDescription = this.settings("home_our_store_description");
      },
      immediate: true,
    },
  },

  methods: {
    async fetchProjects() {
      try {
        const {
          data: { projects },
        } = await IndexData({ reqName: "projects" });
        this.items = projects;
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
